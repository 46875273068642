<template>
    <b-container fluid id="administration">
        <b-row>
            <b-col sm="3" md="2" class="mr-0 pr-0 left-navbar">
                <b-list-group>
                    <router-link to="/administration/panel" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Administration") }}</span>
                        </b-list-group-item>
                    </router-link>
 
                    <router-link to="/administration/account" v-if="$checkPermission('list-account')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Accounts") }}</span>
                        </b-list-group-item>
                    </router-link>

                    <router-link to="/administration/company" v-if="$checkPermission('view-company')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Company") }}</span>
                        </b-list-group-item>
                    </router-link>

                    <router-link to="/administration/landingpage" v-if="$checkPermission('view-landing-page')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.LandingPage") }}</span>
                        </b-list-group-item>
                    </router-link>

                    <router-link to="/administration/roles" v-if="$checkPermission('list-role')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Roles") }}</span>
                        </b-list-group-item>
                    </router-link>

                    <router-link to="/administration/targets" v-if="$checkPermission('view-target')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Targets") }}</span>
                        </b-list-group-item>
                    </router-link>
                    
                    <router-link to="/administration/template" v-if="$checkPermission('list-template')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Template") }}</span>
                        </b-list-group-item>
                    </router-link>

                    <router-link to="/administration/attachment" v-if="$checkPermission('modify-template')" v-slot="{ href, route, navigate, isActive }">
                        <b-list-group-item href="#" :class="[isActive && 'router-link-active']" @click="navigate">
                            <span>{{ $t("Navbar.Attachment") }}</span>
                        </b-list-group-item>
                    </router-link>

                    <b-toast id="default-campaign-values" :title="$t('Company.CampaignDefaultValues')" class="mt-2 mr-2" static no-auto-hide>
                        <p v-for="(attr, i) in campaignAttributes" :key="i" > {{$t(attr.label)}}: {{attr.configKey !== 'templateSpecificAssignRate' ? config.defaults.campaign[attr.configKey] : config.defaults.campaign[attr.configKey] * 100 }} </p>
                    </b-toast>

                </b-list-group>
            </b-col>
            <b-col sm="9" md="10" class="ml-0 pl-0">
                <router-view class="right-view px-4 py-4 mb-4"
                    @companyToggleArchive="$emit('companyToggleArchive', $event)" 
                    @companyUpdated="$emit('companyUpdated', $event)" 
                    @companyDeleted="$emit('companyDeleted', $event)">
                </router-view>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import syspropService from '../services/sysprop.service'
export default {
    name: "cg-administration",
    data: function() {
        return {
            config: {
                defaults: {
                    campaign: ""
                }
            },
            campaignAttributes:[
                    {   
						label: 'Company.CampaignDuration',
						configKey: 'duration',
					},
					{   
						label: 'Company.SendEmailDuration',
						configKey: 'sendEmailPhaseDuration',
					},
					{   
						label: 'Company.N_CampaignTemplates',
						configKey: 'proposedTemplates',
					},
					{   
						label: 'Company.N_MinCampaignTemplates',
						configKey: 'minimumTemplates',
					},
                    {   
						label: 'Company.TemplateSpecificAssignRate',
						configKey: 'templateSpecificAssignRate',
					},
					{   
						label: 'Company.N_OnboardingCampaigns',
						configKey: 'onboardingPhaseLength',
					}
            ]
        }
    },
    async mounted() {
        const path = "/administration/panel";
        let configdata = await syspropService.getConfig();
		this.config = configdata.data;
        if (this.$route.path !== path) {
            this.$router.push(path);
        }
    }
};
</script>

<style lang="less">
#administration {
    .left-navbar {
        z-index: 1;
    }
    .right-view {
        background-color: white;
        width: auto;
        min-height: 700px;
        border: @cg-border-light;
        margin-left: -1px !important;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
    .list-group-item:first-of-type {
        border-top-right-radius: 0;
    }
    .list-group-item:last-of-type {
        border-bottom-right-radius: 0;
    }
    .router-link-active,
    .list-group-item-action:focus,
    .list-group-item-action:active {
        background-color: white;
        border-right: none;
    }
}
</style>